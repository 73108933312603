import { navigate } from 'gatsby';
import { PROD_HOSTNAMES, PROD_API_URL, NONPROD_API_URL, PROD_BETA_HOSTNAMES } from './constants';

export const getApiBaseUrl = () => {
  if (PROD_HOSTNAMES.includes(window.location.hostname) || PROD_BETA_HOSTNAMES.includes(window.location.hostname)) {
    return PROD_API_URL;
  }
  return NONPROD_API_URL;
};

export const productApiErrorHandler = (error) => {
  window?.newrelic?.noticeError(error, { fromIcatalyst: true });
  navigate('/500/');
};
