import React, { useContext, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import Button from '@cof/graffiti-alley-spray-cans/molecules/Button';
import Email from '@cof/graffiti-alley-spray-cans/molecules/Email';
import { SprayCanContext } from '@cof/graffiti-alley-spray-cans/context/app-context';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import { getApiBaseUrl } from '../../lib/api';

import './preferences.scss';

const Preferences = () => {
  const [submitted, setSubmitted] = useState(false);
  const [displayApiError, setDisplayApiError] = useState(false);
  const { data, validateAndSubmit } = useContext(SprayCanContext);

  const onSubmit = useCallback(
    (event) => {
      const onSubmitCallback = async () => {
        event.preventDefault();
        try {
          const response = await fetch(`${getApiBaseUrl()}/enterprise/privacy/preferences/emails`, {
            method: 'POST',
            headers: {
              Accept: 'v=1',
              'Content-Type': 'application/json',
              'X-Request-Id': 'SSP',
              locationIdentifier: 'CAN',
            },
            body: JSON.stringify({
              emailAddresses: [{ emailAddress: data.contactEmail }],
              // Email opt-out code for the endpoint
              preferenceCode: 2,
              userSourceGroupId: 200,
            }),
          });
          if (response.status >= 400) {
            throw new Error(`API Error: ${response.status} ${response.statusText}`);
          }
          setSubmitted(true);
        } catch (err) {
          console.error(err);
          setDisplayApiError(true);
        }
      };

      return validateAndSubmit(event, onSubmitCallback);
    },
    [data.contactEmail, validateAndSubmit],
  );

  return (
    <Layout pageName="SSP-preferences-page" className="SSP-preferences-email">
      <div className="SSP-preferences-page">
        {submitted ? (
          <section className="page-content SSP-unsubscribed">
            <h1>
              <FormattedMessage id="pages.ssp-preferences.unsubscribed.h1" />
            </h1>
            <p>
              <FormattedMessage id="pages.ssp-preferences.unsubscribed.p0" />
            </p>
          </section>
        ) : (
          <section className="page-content">
            <h1>
              <FormattedMessage id="pages.ssp-preferences.h1" />
            </h1>
            <p>
              <FormattedMessage id="pages.ssp-preferences.p0" />
            </p>
            <p>
              <FormattedMessage
                id="pages.ssp-preferences.p1"
                values={{
                  signInOnlineAccount: (
                    <Link to="/sign-in" id="ssp-sign-in-link">
                      <FormattedMessage id="pages.ssp-preferences.sign-in-online-account" />
                    </Link>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="pages.ssp-preferences.p2"
                values={{
                  privacyPolicy: (
                    <Link to="/privacypolicy" id="ssp-privacy-policy-link">
                      <FormattedMessage id="pages.ssp-preferences.privacy-policy" />
                    </Link>
                  ),
                }}
              />
            </p>
            <h2>
              <FormattedMessage id="pages.ssp-preferences.email.h2-0" />
            </h2>
            <form>
              <div className="email-field-wrapper">
                <Email isTooltipRequired={false} withConfirmation={true} />
              </div>
              {displayApiError && (
                <p className="api-error" role="status" aria-live="polite">
                  <FormattedMessage id="pages.ssp-preferences.api-error" />
                </p>
              )}
              <Button className="action button small see-cards-button SSP-email-cta" onClick={onSubmit}>
                <FormattedMessage id="pages.ssp-preferences.email.cta" />
              </Button>
            </form>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default Preferences;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.ssp-preferences.page-title"
    descriptionKey="pages.ssp-preferences.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
